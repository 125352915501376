import React from 'react';
import {MultiSelect} from 'react-multi-select-component';


const Select = ({multi = true, ...props}) => {
  return multi ?
    <MultiSelect {...props} /> :
    <MultiSelect
      hasSelectAll={false}
      ClearSelectedIcon={<></>}
      closeOnChangedValue={true}
      {...props}
    />;
};

export default Select;
