import React, {lazy, Suspense, useState, useEffect, useMemo} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import './App.scss';

import DarwinLoader from './components/common/DarwinLoader';
import Layout from './components/common/Layout';
import DuplicatesView from './pages/DuplicatesView';
import HelpsView from './pages/HelpsView';


const TranslationsView = lazy(() => import('./pages/TranslationsView'));


export default function App() {
  const [activeSidebar, setActiveSidebar] = useState('');
  
  const location = useLocation();
  
  useEffect(() => {
    setActiveSidebar(location.pathname);
  }, [location]);
  
  const routes = useMemo(() => [
    {
      path: '/translations',
      element: <TranslationsView />
    },
    {
      path: '/duplicates',
      element: <DuplicatesView />
    },
    {
      path: '/helps',
      element: <HelpsView />
    }
  ], []);
  
  return (
    <Suspense fallback={<DarwinLoader />}>
      <Routes>
        <Route element={<Layout
          activeSidebar={activeSidebar}
          setActiveSidebar={setActiveSidebar}
        />}>
          {routes.map((e, idx) => <Route key={`route-${idx}`} path={e.path} element={e.element} />)}
        </Route>
        <Route path='*' element={<Navigate to={'/translations'} />} />
      </Routes>
    </Suspense>
  );
}
