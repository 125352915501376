import React from 'react';
import {Button, Modal} from 'react-bootstrap';


const AreYouSureModal = ({show, title, body, onConfirm, onCancel, confirmText = 'Yes', cancelText = 'Cancel'}) => {
  return (
    <Modal show={show} onHide={() => onCancel()} centered>
      <Modal.Header className='justify-content-center' closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-around flex-wrap'>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='muted' onClick={() => onCancel()}>
          {cancelText}
        </Button>
        <Button onClick={() => onConfirm()}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AreYouSureModal;
