import React from 'react';
import {Dropdown, Spinner} from 'react-bootstrap';

const MoreOptions = ({actions, size = '', className = '', direction}) => {
  return (
    <Dropdown className={className} drop={direction}>
      <Dropdown.Toggle variant='' className='p-1 more-actions'>
        <i className={`bi bi-three-dots-vertical ${size}`} />
      </Dropdown.Toggle>
      
      <Dropdown.Menu>
        {actions.map(action =>
          <Dropdown.Item key={action.name} onClick={() => action.action()} disabled={action.disabled || false}>
            {action.loading ? <Spinner className='more-options-spinner me-3' /> :
              action.icon ? <i className={`bi bi-${action.icon} me-3`} /> : undefined
            }
            {action.name}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreOptions;
