import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {Col, Dropdown, DropdownButton, Form, Pagination, Row, Spinner, Table} from 'react-bootstrap';
import {useSearchParams} from 'react-router-dom';
import {debounce} from 'lodash';
import Select from './Select';


const PaginatedTable = forwardRef((props, ref) => {
  const {
    searchable,
    sortOptions,
    tableData,
    filters = [],
    backendPaginated = true,
    fetch,
    rowStyle,
    onClick,
    className = '',
    urlSearchParams = true,
    searchFunction = undefined
  } = props;
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(backendPaginated ? {} : []);
  const [tablePage, setTablePage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [filtersOptions, setFilterOptions] = useState({});
  const [page, setPage] = useState(urlSearchParams ? parseInt(searchParams.get('page')) || 1 : 1);
  const [search, setSearch] = useState(urlSearchParams ? searchParams.get('search') || '' : '');
  const [order, setOrder] = useState(urlSearchParams ?
    searchParams.get('order') || (sortOptions && sortOptions.length > 0 ? sortOptions[0].value : undefined) :
    (sortOptions && sortOptions.length > 0 ? sortOptions[0].value : undefined));
  const [itemsPerPage, setItemsPerPage] = useState(urlSearchParams ? parseInt(searchParams.get('itemsPerPage')) || 20 : 20);
  
  useImperativeHandle(ref, () => ({
    updateById: (newElement) => {
      setData(prev => {
        let temp = prev[page].map(e => e.id === newElement.id ? newElement : e);
        setTablePage(temp);
        return {...prev, [page]: temp};
      });
    },
    removeElement: async () => {
      await fetchData(page, true);
    }
  }));
  
  useEffect(prev => {
    if (!backendPaginated) {
      (async () =>
          await fetchData(page, true)
      )();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, page]);
  
  const fetchData = async (page, reset = false) => {
    if (backendPaginated) {
      if (reset || !(page in data)) {
        const res = await fetch(getQueryParams(page));
        if (res.ok) {
          setTotalPages(res.body.metadata.total_pages);
          setTotalItems(res.body.metadata.total_items);
        }
        if (reset)
          setData({[page]: res.body.results});
        else
          setData({...data, [page]: res.body.results});
        setTablePage(res.body.results);
      }
      else {
        setTablePage(data[page]);
      }
    }
    else {
      const dataStart = (page - 1) * itemsPerPage;
      
      let new_data = fetch;
      if (!!search && searchFunction)
        new_data = new_data.filter(d => searchFunction(d, search));
      
      if (reset) {
        setData(new_data);
        setTotalItems(new_data.length);
      }
      setTotalPages(Math.ceil(new_data.length / itemsPerPage));
      setTablePage(new_data.slice(dataStart, dataStart + itemsPerPage));
    }
  };
  
  useEffect(() => {
    let filtersDefault = {};
    for (const f of filters) {
      const fromSearchParams = urlSearchParams ? searchParams.get(f.param) || '' : '';
      filtersDefault[f.param] = (fromSearchParams === '' ? [] : fromSearchParams.split(',')).map(e => ({
        label: e,
        value: e
      }));
    }
    
    setFilterOptions(filtersDefault);
    
    return () => {
      debounceSearchOnChange.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData(page);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);
  
  const updateSearchParams = (param, value) => {
    if (!urlSearchParams) return;
    setSearchParams(prev => {
      prev.set(param, value.toString());
      return prev;
    }, {replace: true});
  };
  
  const onFilterChange = async (filter, e) => {
    if (filter.onChange)
      filter.onChange(e.map(f => f.value));
    
    setFilterOptions(prev => {
      let ret = {...prev};
      ret[filter.param] = e;
      return ret;
    });
  };
  
  const getQueryParams = (_page = page) => {
    let qp = {
      page: _page,
      page_size: itemsPerPage,
      search: search,
      ordering: order
    };
    
    for (const filter of filters) {
      if (!filtersOptions[filter.param]) continue;
      qp[filter.param] = Object.values(filtersOptions[filter.param]).map(e => e.value).join(',');
    }
    
    return qp;
  };
  
  const handleSearch = (e) => setSearch(e);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchOnChange = useMemo(() => debounce(handleSearch, 1000), []);
  
  const firstFetch = async () => {
    setIsLoadingTable(true);
    setPage(1);
    await fetchData(1, true);
    setIsLoadingTable(false);
  };
  
  useEffect(() => {
    updateSearchParams('order', order || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);
  
  useEffect(() => {
    updateSearchParams('search', search || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  
  useEffect(() => {
    updateSearchParams('itemsPerPage', itemsPerPage || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage]);
  
  useEffect(() => {
    updateSearchParams('page', page || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  
  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
    
    for (const [key, value] of Object.entries(filtersOptions)) {
      updateSearchParams(key, value.map(e => e.value).join(','));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersOptions]);
  
  useEffect(() => {
    if (!isLoaded) return;
    (async () => {
      await firstFetch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, search, itemsPerPage, filtersOptions]);
  
  const handlePageChange = async (page) => {
    setIsLoadingTable(true);
    setPage(page);
    await fetchData(page);
    setIsLoadingTable(false);
  };
  
  const renderPagination = () => {
    const currentPage = page;
    
    const prevDisabled = currentPage <= 1;
    const nextDisabled = currentPage >= totalPages;
    
    return (
      <Row>
        <DropdownButton
          variant='secondary'
          title={itemsPerPage}
          className='w-auto'
        >
          <Dropdown.Item onClick={() => setItemsPerPage(10)}>10</Dropdown.Item>
          <Dropdown.Item onClick={() => setItemsPerPage(20)}>20</Dropdown.Item>
          <Dropdown.Item onClick={() => setItemsPerPage(50)}>50</Dropdown.Item>
          <Dropdown.Item onClick={() => setItemsPerPage(100)}>100</Dropdown.Item>
        </DropdownButton>
        <Pagination className='w-auto'>
          <Pagination.First disabled={prevDisabled} onClick={() => handlePageChange(1)} />
          <Pagination.Prev disabled={prevDisabled}
                           onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} />
          <Pagination.Item>{((currentPage - 1) * itemsPerPage) + 1} - {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems}</Pagination.Item>
          <Pagination.Next disabled={nextDisabled}
                           onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} />
          <Pagination.Last disabled={nextDisabled} onClick={() => handlePageChange(totalPages)} />
        </Pagination>
      </Row>
    );
  };
  
  return (
    <>
      <div className={`${className} bordered dark-bg`}>
        {filters.length > 0 && <Row>
          {filters.map(filter =>
            filter.type === undefined ?
              <Form.Group as={Col} key={`filter-${filter.label}`}>
                <Form.Label column>{filter.label}</Form.Label>
                <Select
                  options={filter.options.map(c => ({
                    label: c,
                    value: c
                  }))}
                  isLoading={filter.isLoading || false}
                  value={filtersOptions[filter.param] || []}
                  onChange={(e) => onFilterChange(filter, e)}
                  labelledBy={filter.label}
                />
              </Form.Group> :
              <Form.Group as={Col} xs="auto" key={`filter-${filter.label}`}>
                <Form.Label column>{filter.label}</Form.Label>
                <Form.Control
                  type={filter.type}
                  placeholder={filter.placeholder ?? ''}
                  onBlur={(e) => onFilterChange(filter, [{value: e.currentTarget.value}])}
                  defaultValue={filtersOptions[filter.param]?.[0]?.value || ''}
                  min={filter.min}
                  max={filter.max}
                  step={filter.step}
                ></Form.Control>
              </Form.Group>
          )}
        </Row>}
        <hr />
        <Row className='mb-4'>
          {searchable &&
            <Col>
              <Form.Control
                type='text'
                placeholder='Search...'
                defaultValue={search}
                onChange={(e) => debounceSearchOnChange(e.target.value)}
              />
            </Col>
          }
          {(sortOptions && sortOptions.length > 0) &&
            <Col>
              <Form.Select
                defaultValue={order}
                onChange={(e) => setOrder(e.target.value)}
              >
                {sortOptions.map(e => <option key={`table-sort-${e.value}`}
                                              value={e.value}>{e.name}</option>)}
              </Form.Select>
            </Col>
          }
        </Row>
        {isLoading ? <Spinner /> :
          <>
            <Table responsive striped borderless hover variant='dark'
                   className={isLoadingTable ? 'loading-table' : ''}>
              <thead>
              <tr key='table-header'>
                {tableData.map((col, i) => {
                    return col.show && (<th key={`table-header-${i}`}>{col.header}</th>);
                  }
                )}
              </tr>
              </thead>
              <tbody>
              {tablePage.map((c, ci) =>
                <tr
                  key={`table-${ci}`}
                  onClick={() => onClick ? onClick(c) : undefined}
                  className={`align-middle ${onClick ? 'clickable' : ''} ${rowStyle ? rowStyle(c) : ''}`}
                >
                  {tableData.map((col, col_i) => {
                      return col.show && (<td key={`table-${ci}-${col_i}`}
                                              onClick={(e) => col.onClick ? col.onClick(e, c) : undefined}
                                              className={col.className || ''}
                      >
                        {col.data(c)}
                      </td>);
                    }
                  )}
                </tr>
              )}
              </tbody>
            </Table>
            {renderPagination()}
          </>
        }
      </div>
    </>
  );
});

export default PaginatedTable;
