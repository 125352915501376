import React, {useEffect, useMemo, useState} from 'react';
import MoreOptions from '../MoreOptions';
import AreYouSureModal from '../AreYouSureModal';
import {useLocation} from 'react-router-dom';


const DuplicateMoreOptions = ({
  duplicate,
  onEdit,
  onDelete,
  onDeleteStart = undefined,
  className = '',
  size = ''
}) => {
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [allDisabled, setAllDisabled] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const datetime = params.get('datetime');
    
    setAllDisabled(!!datetime);
  }, [location]);
  
  const handleAreYouSureModal = () => {
    setShowAreYouSureModal(true);
  };
  
  const moreOptions = useMemo(() => [
    {
      name: 'Edit',
      icon: 'pencil',
      disabled: allDisabled,
      action: onEdit
    },
    {
      name: 'Delete',
      icon: 'trash-fill',
      disabled: isBeingDeleted || allDisabled,
      loading: isBeingDeleted,
      action: handleAreYouSureModal
    }
  ], [allDisabled, isBeingDeleted, onEdit]);
  
  const handleReleaseDeletion = async () => {
    if (onDeleteStart)
      onDeleteStart();
    setShowAreYouSureModal(false);
    setIsBeingDeleted(true);
    const response = await fetch(`${process.env.REACT_APP_TRANSLATIONS_URL ?? ''}/api/deleteDuplicates`, {
      method: 'POST',
      body: JSON.stringify({duplicates_PK: duplicate.duplicates_PK})
    });
    if (!response.ok) {
      // TODO: handle error
    }
    const data = await response.json();
    setIsBeingDeleted(false);
    onDelete(data);
  };
  
  const renderAreYouSureModal = () => {
    return <AreYouSureModal
      show={showAreYouSureModal}
      title='Delete Duplicate'
      body={<span>Are you sure you want to delete <b>{duplicate?.category}</b>.<b>{duplicate?.translationID}</b>?</span>}
      onConfirm={() => handleReleaseDeletion()}
      onCancel={() => setShowAreYouSureModal(false)}
      confirmText='Delete'
    />;
  };
  
  return <>
    <MoreOptions
      actions={moreOptions}
      className={className}
      size={size}
      direction='end'
    />
    {renderAreYouSureModal()}
  </>;
};

export default DuplicateMoreOptions;