import {Button, Container, Form, Modal, Spinner} from 'react-bootstrap';
import React, {useState} from 'react';
import AreYouSureModal from '../AreYouSureModal';


const DuplicatesEditModal = ({
  showModal,
  setShowModal,
  duplicateToEdit = {},
  onEditSuccess
}) => {
  const create = !duplicateToEdit.category;
  const [isDuplicateSaving, setIsDuplicateSaving] = useState(false);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  
  const handleModalClose = () => {
    setShowModal(false);
    setIsDuplicateSaving(false);
    setShowAreYouSureModal(false);
  };
  
  async function sendRequest() {
    const formDiv = document.getElementById('add-new-duplicate-form');
    const translationID = formDiv.querySelector('#add-new-duplicates-form-translationID input')?.value;
    const category = formDiv.querySelector('#add-new-duplicates-form-category input')?.value;
    const value = formDiv.querySelector('#add-new-duplicates-form-reference input')?.value;
    
    const response = await fetch(`${process.env.REACT_APP_TRANSLATIONS_URL ?? ''}/api/${create ? 'addDuplicate' : 'updateDuplicate'}`, {
      method: 'POST',
      body: JSON.stringify({
        duplicates_PK: duplicateToEdit.duplicates_PK,
        translationID,
        category,
        value
      })
    });
    if (!response.ok) {
      // TODO: add error message
      setIsDuplicateSaving(false);
      return;
    }
    
    const data = await response.json();
    onEditSuccess(data, create);
    handleModalClose();
  }
  
  async function postCreateUpdateDuplicates() {
    setIsDuplicateSaving(true);
    const formDiv = document.getElementById('add-new-duplicate-form');
    const translationID = formDiv.querySelector('#add-new-duplicates-form-translationID input')?.value;
    const category = formDiv.querySelector('#add-new-duplicates-form-category input')?.value;
    const value = formDiv.querySelector('#add-new-duplicates-form-reference input')?.value;
    
    if (!value || !translationID || !category) {
      // TODO: add required alert
      setIsDuplicateSaving(false);
      return;
    }
    
    if (
      !create && (
        translationID !== duplicateToEdit.translationID ||
        category !== duplicateToEdit.category
      )
    ) {
      setShowAreYouSureModal(true);
      return;
    }
    
    await sendRequest();
  }
  
  return (
    <>
      <Modal size='lg' show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{create ? 'Add new duplicate' : 'Edit duplicate'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='dark-bg h-100 p-3' id='add-new-duplicate-form'>
            <Form.Group className='mb-3' id='add-new-duplicates-form-category'>
              <Form.Label>Category</Form.Label>
              <Form.Control maxLength={50} defaultValue={duplicateToEdit['category'] ?? ''} />
            </Form.Group>
            <Form.Group className='mb-3' id='add-new-duplicates-form-translationID'>
              <Form.Label>TranslationID</Form.Label>
              <Form.Control maxLength={50} defaultValue={duplicateToEdit['translationID'] ?? ''} />
            </Form.Group>
            <Form.Group className='mb-3' id='add-new-duplicates-form-reference'>
              <Form.Label>Reference</Form.Label>
              <Form.Control maxLength={50} defaultValue={duplicateToEdit['value'] ?? ''} />
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleModalClose}>
            Close
          </Button>
          <Button className='d-flex justify-content-center align-items-center' variant='primary' onClick={postCreateUpdateDuplicates} disabled={isDuplicateSaving}>
            <span className={isDuplicateSaving ? 'invisible' : ''}>Save</span>
            {isDuplicateSaving && <Spinner className='position-absolute' style={{height: '1.2rem', width: '1.2rem'}} />}
          </Button>
        </Modal.Footer>
      </Modal>
      <AreYouSureModal
        show={!!showAreYouSureModal}
        title='Edit Duplicates key?'
        body={(
          <>
            <p className='col-12 mb-0'>You are editing the translationID or the category of the duplicate.</p>
          </>
        )}
        onConfirm={() => {
          setShowAreYouSureModal(false);
          return sendRequest();
        }}
        onCancel={() => {
          setIsDuplicateSaving(false);
          setShowAreYouSureModal(false);
        }}
      />
    </>
  );
};

export default DuplicatesEditModal;