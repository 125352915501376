import React from 'react';


const Footer = () => {
  return (
    <footer className='text-center text-lg-start'>
      <div className='text-center p-3'>
        <a href='https://darwininteractive.com/'>Darwin Interactive</a>
      </div>
    </footer>
  );
};

export default Footer;
