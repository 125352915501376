import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';


const app = createRoot(document.getElementById('App'));

app.render(
  <BrowserRouter basename=''>
    <App />
  </BrowserRouter>
);