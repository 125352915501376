import {Outlet} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import Sidebar from './Sidebar';
import Footer from './Footer';
import React from 'react';


const Layout = (props) => {
  return (
    <>
      {<Container fluid className='m-0 w-100'>
        <Row className='h-100'>
          <Col className='col-auto'>
            <Row className='sticky-top dark-bg'>
              <Sidebar
                active={props.activeSidebar}
                setActive={props.setActiveSidebar}
              />
            </Row>
          </Col>
          <Col className='w-100 overflow-y-scroll d-flex flex-column justify-content-start'>
            <Row className='p-3 flex-column'><Outlet /></Row>
            <Row className='mt-auto'><Footer /></Row>
          </Col>
        </Row>
      </Container>}
    </>
  );
};

export default Layout;