
const HelpsView = () => {
  
  return (
    <>
      <div className='d-flex items-center justify-content-between mb-5'>
        <h1>Helps</h1>
      </div>
    </>
  );
};

export default HelpsView;
