import React from 'react';
import {ReactComponent as DarwinLoading} from '../../assets/images/darwin_loading.svg';


const DarwinLoader = () => {
  return (
    <>
      <DarwinLoading className={'darwin-loading vh-100 vw-100'} />
    </>
  );
};

export default DarwinLoader;
