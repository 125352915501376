import React from 'react';
import {Container, Nav, Image, Navbar} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';


const navData = [
  {
    id: 'sidebar-0',
    icon: 'pencil',
    text: 'Translations',
    link: '/translations',
    related: ['/translations']
  },
  {
    id: 'sidebar-1',
    icon: 'copy',
    text: 'Duplicates',
    link: '/duplicates',
    related: ['/duplicates']
  },
  {
    id: 'sidebar-2',
    icon: 'info-square',
    text: 'Helps',
    link: '/helps',
    related: ['/helps']
  }
];

const Sidebar = (props) => {
  const navigate = useNavigate();
  let collapsed = props.collapsed;
  let setCollapsed = props.setCollapsed;
  
  const handleNavLink = (e, link) => {
    e.preventDefault();
    props.setActive(link);
    navigate(link);
  };
  
  return (
    <Navbar className='vh-100 border-end pb-0'>
      <Container fluid className={`flex-column h-100 px-0 pt-2`}>
        <Navbar.Brand onClick={(e) => handleNavLink(e, '/')} className='me-0 px-3'>
          <Image
            height='32'
            className='d-inline-block'
            src={collapsed ?
              require('../../assets/images/darwin-logo-mini.png') :
              require('../../assets/images/darwin-logo.png')}
          />{' '}
        </Navbar.Brand>
        <Nav className={`mt-5 w-100 flex-column flex-fill justify-content-start`}>
          {navData.map(nav =>
            <Link
              key={nav.id}
              to={nav.link}
              className={`${collapsed ? 'ps-3' : 'ps-3 pe-5'} py-3 mh-10 menu-link ${nav.related.some((link => props.active.includes(link))) ? 'menu-link-active' : ''}`}
              onClick={(e) => handleNavLink(e, nav.link)}
            >
              <i className={`menu-icon bi bi-${nav.icon} ${collapsed ? '' : 'me-3'}`}></i>
              {!collapsed && nav.text}
            </Link>
          )}
        </Nav>
        <Nav className='justify-content-start border-top w-100 ps-3'>
          <i onClick={() => setCollapsed(!collapsed)} className={`menu-icon bi bi-chevron-double-${collapsed ? 'right' : 'left'}`}></i>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Sidebar;