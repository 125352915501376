import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button} from 'react-bootstrap';
import PaginatedTable from '../components/PaginatedTable';
import DuplicatesEditModal from '../components/duplicates/DuplicatesEditModal';
import DuplicatesMoreOptions from '../components/duplicates/DuplicatesMoreOptions';
import {Link, useLocation} from 'react-router-dom';


const DuplicatesView = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const duplicatesData = useRef([]);
  const duplicateToEdit = useRef({});
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDatetime, setSelectedDatetime] = useState('');
  const location = useLocation();
  
  useEffect(() => {
    fetchAllDuplicates().then((data) => {
      setFilteredData(data);
      setCategories([...(new Set(data.map((d) => d.category)))]);
      
      tableData.current.splice(-1, 0, ...Object.keys(data[0])
        .filter(k => !tableData.current.map(d => d.key).includes(k))
        .map(k => {
          return {
            header: (k.split('_').map((code, i) => i === 0 ? code.toLowerCase() : code.toUpperCase()).join('-')),
            show: true,
            key: k,
            data: (duplicate) => duplicate[k]
          };
        })
      );
      setLoading(false);
    });
  }, []);
  
  const updateFilteredData = useCallback(() => {
    setFilteredData(duplicatesData.current.filter(d =>
      selectedCategories.includes(d.category) ||
      selectedCategories.length === 0
    ));
  }, [selectedCategories]);
  
  const onDateTimeChange = useCallback((datetime) => {
    let utcTimestamp = '';
    if (datetime) {
      const localDate = new Date(datetime);
      utcTimestamp = localDate.getTime();
    }
    if (selectedDatetime === utcTimestamp) return;
    fetchAllDuplicates({date: utcTimestamp}).then(data => {
      duplicatesData.current = data;
      updateFilteredData();
    });
    return utcTimestamp;
  }, [selectedDatetime, updateFilteredData]);
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category');
    const datetime = params.get('datetime');
    
    if (category) setSelectedCategories(category.split(','));
    if (datetime) onDateTimeChange(datetime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  
  const tableRef = useRef();
  
  const tableData = useRef(
    [
      {
        header: '',
        show: true,
        data: (duplicate) =>
          <DuplicatesMoreOptions
            duplicate={duplicate}
            onEdit={() => {
              duplicateToEdit.current = duplicate;
              setShowEditModal(true);
            }}
            onDelete={(duplicateDeleted) => {
              const index = duplicatesData.current.findIndex(d => d.duplicates_PK === duplicateDeleted.duplicates_PK);
              if (index === -1) return;
              duplicatesData.current.splice(index, 1);
              
              updateFilteredData();
            }}
          />
      },
      {
        header: 'Category',
        show: true,
        key: 'category',
        data: (duplicate) => duplicate.category
      },
      {
        header: 'TranslationsID',
        show: true,
        key: 'translationID',
        data: (duplicate) => duplicate.translationID
      },
      {
        header: 'PK',
        show: false,
        key: 'duplicates_PK'
      },
      {
        header: 'Reference',
        show: true,
        key: 'value',
        data: (duplicate) => <Link to={`/translations?search=${encodeURIComponent(duplicate.value)}`}>{duplicate.value}</Link>
      }
    ]
  );
  
  const filterOptions = useMemo(() => [
    {
      param: 'category',
      label: 'Category',
      options: categories,
      onChange: setSelectedCategories
    },
    {
      param: 'datetime',
      label: 'Date Time',
      type: 'datetime-local',
      step: 1,
      onChange: ([datetime]) => {
        return onDateTimeChange(datetime);
      }
    }
  ], [categories, onDateTimeChange]);
  
  useEffect(updateFilteredData, [updateFilteredData]);
  
  async function fetchAllDuplicates({date} = {}) {
    setSelectedDatetime(date ?? '');
    let url = `${process.env.REACT_APP_TRANSLATIONS_URL ?? ''}/api/getAllDuplicates`;
    
    if (date) {
      const queryParamsString = new URLSearchParams({
        date
      }).toString();
      url += '?' + queryParamsString;
    }
    
    const response = await fetch(url);
    const data = await response.json();
    duplicatesData.current = data;
    return data;
  }
  
  return (
    <>
      <div className='d-flex items-center justify-content-between mb-5'>
        <h1>Duplicates</h1>
        <div className='d-flex'>
          <Button className='mt-auto' variant='success' onClick={() => setShowEditModal(true)}>Add new Duplicate</Button>
        </div>
      </div>
      {!isLoading && (<PaginatedTable
        ref={tableRef}
        className='px-5 py-4'
        searchable
        searchFunction={(d, search) => {
          return Object.entries(d).some(([k, v]) => {
            // search for empty (null) values
            if (search === '[EMPTY]') {
              return !v;
            }
            
            return v?.toLowerCase().includes(search.toLowerCase());
          });
        }}
        filters={filterOptions}
        tableData={tableData.current}
        backendPaginated={false}
        fetch={filteredData}
      />)}
      {<DuplicatesEditModal
        showModal={showEditModal}
        duplicateToEdit={duplicateToEdit.current}
        setShowModal={(show) => {
          if (!show) duplicateToEdit.current = {};
          setShowEditModal(show);
        }}
        onEditSuccess={(data, isNew) => {
          if (!data) return;
          
          if (duplicateToEdit.current.duplicates_PK === data.duplicates_PK) {
            Object.assign(duplicateToEdit.current, data);
          }
          
          duplicateToEdit.current = {};
          if (isNew) duplicatesData.current.push(data);
          updateFilteredData();
        }}
      />}
    </>
  );
};

export default DuplicatesView;
